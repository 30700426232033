@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.ratio-box {
	position: relative;
	overflow: hidden;
}
.ratio-box:before {
	display: block;
	content: '';
	width: 100%;
	padding-top: calc((1 / 1) * 100%);
}
.ratio-box--16-9:before {
	padding-top: calc((9 / 16) * 100%);
}
.ratio-box__inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
}
