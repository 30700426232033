@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.home-content {
  padding-top: 56px;
  padding-bottom: 56px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__hero-section {
    height: 70vw;
    background-image: url(/assets/images/home-hero.jpg);
    background-position: top center;
    background-size: cover;

    &__block {
      color: #fff;
      max-width: 200px;
      font-size: 16px;
      line-height: 1.3;
      text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.5);
      @media (min-width: 768px) {
        max-width: 300px;
        font-size: 24px;
      }

      .block__number {
        font-size: 48px;
        font-weight: 700;
        line-height: 1;
        @media (min-width: 768px) {
          font-size: 120px;
          font-weight: 700;
          line-height: 1;
        }
      }

      .block__unit {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
