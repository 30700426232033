@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.about-us-content {
  padding-top: 56px;
  padding-bottom: 56px;
  background-color: #e5e5e5;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__hero-section {
    height: 70vw;
    background-image: url(/assets/images/about-us-hero.jpg);
    background-position: top center;
    background-size: cover;
    @media (min-width: 992px) {
      height: 70vh;
    }

    &__block {
      color: #fff;
      // max-width: 200px;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      @media (min-width: 768px) {
        // max-width: 300px;
        font-size: 98px;
      }
    }
  }

  &__info-section {
    padding-top: 32px;

    @media (min-width: 768px) {
      padding-top: 64px;
    }
  }
}
