@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;

  .navbar {
    @media (min-width: 992px) {
      height: 100px;
    }
  }

  .brand-logo {
    width: 44px;
    @media (min-width: 992px) {
      width: 84px;
    }
  }

  .navbar-nav {
    @media (max-width: 991px) {
      align-items: flex-start;

      overflow-y: auto;
      height: calc(100vh - 64px);
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    ul.wrapper {
      @media (min-width: 992px) {
        display: none;
      }

      & > li.active {
        border: 1px solid $primary;
        border-radius: 16px;
        padding: 0 8px;
      }

      a {
        color: #333;
        text-decoration: none;
      }
    }

    &:hover {
      ul.wrapper {
        @media (min-width: 992px) {
          position: absolute;
          top: 100%;
          padding-top: 16px;
          display: flex;
          flex-flow: row nowrap;
          white-space: nowrap;
        }
      }
    }

    &.active {
      &::after {
        content: '';
        position: absolute;
        left: calc(50% - 30px);
        bottom: -4px;
        width: 60px;
        height: 8px;
        background-color: #8ac43f;
        border-radius: 8px;
      }

      .nav-link {
        color: $primary;
      }
    }

    .nav-link {
      @media (min-width: 992px) {
        text-align: center;
      }
    }
  }

  .sub-nav {
    .wrapper {
      display: flex;
      flex-flow: row nowrap;

      & > * {
        margin-right: 24px;
      }
    }
  }

  @media (min-width: 992px) {
    .nav-sub-item {
      ul {
        display: none !important;
      }

      &:hover {
        ul {
          display: flex !important;
        }
      }
    }
  }
}
