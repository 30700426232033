@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.product-service-content {
  padding-top: 56px;
  // padding-bottom: 56px;
  // background-color: #e5e5e5;

  @media (min-width: 992px) {
    padding-top: 100px;
    // padding-bottom: 100px;
  }

  .hero-section {
    height: 70vw;
    background-image: url(/assets/images/product-service-hero.jpg);
    background-position: top center;
    background-size: cover;
    @media (min-width: 992px) {
      height: 70vh;
    }

    &__block {
      color: #fff;
      // max-width: 200px;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      @media (min-width: 768px) {
        // max-width: 300px;
        font-size: 98px;
      }
    }
  }

  .info-section {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (min-width: 768px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    &--1 {
      color: $primary;
      background-color: #e5e5e5;

      h2,
      .h2,
      ul,
      li {
        color: $primary;
        // line-height: 1.2;
      }
    }

    &--2 {
      color: #fff;
      background-color: $primary;

      h2,
      .h2,
      ul,
      li {
        color: #fff;
        // line-height: 1.2;
      }
    }

    &--3 {
      color: #fff;
      background-color: #009ebb;

      h2,
      .h2,
      ul,
      li {
        color: #fff;
        // line-height: 1.2;
      }
    }
  }

  .fig-section {
    // background-position: top center;
    // background-size: cover;

    // padding-top: 32px;
    // padding-bottom: 32px;
    // @media (min-width: 768px) {
    //   padding-top: 100px;
    //   padding-bottom: 100px;
    // }

    color: #fff;

    h2,
    .h2,
    ul,
    li {
      color: #fff;
      // line-height: 1.2;
    }

    &--1 {
      background-image: url(/assets/images/product-service-fig.jpg);
    }

    &__heading {
      font-size: 36px;
      line-height: 1.2;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
      @media (min-width: 768px) {
        font-size: 64px;
        line-height: 1.1;
      }
    }
  }
}
