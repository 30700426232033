@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.main-footer {
  color: #fff;
  background-color: $primary;
  font-size: 14px;
}
